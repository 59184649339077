const LOCAL_DEV_PORT = 443;

module.exports = {
  development: {
    host: "db",
    s3Bucket: "noipm-local",
    officerBucket: "nopd-officers-local",
    exportsBucket: "noipm-exports-local",
    referralLettersBucket: "noipm-referral-letters-local",
    complainantLettersBucket: "noipm-complainant-letters-local",
    authentication: {
      engine: "auth0",
      clientID: "iT3f0mGqJGDZu8UzQaOHeNGT7O0x43ZB",
      domain: "noipm-ci.auth0.com",
      publicKeyURL: "https://noipm-ci.auth0.com/.well-known/jwks.json",
      audience: "https://noipm-ci.herokuapp.com/",
      issuer: "https://noipm-ci.auth0.com/",
      algorithm: "RS256",
      nicknameKey: "https://noipm-ci.herokuapp.com/nickname",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://noipm-ci.auth0.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://api.github.com",
        `wss://localhost:${LOCAL_DEV_PORT}`,
      ],
    },
    corsOrigin: "*",
    winston: {
      logLevel: "info",
      json: true,
    },
    queue: {
      host: "redis",
      port: 6379,
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  test: {
    host: process.env.CIRCLECI ? "localhost" : "db",
    s3Bucket: "noipm-local",
    port: 5432,
    exportsBucket: "noipm-exports-test",
    referralLettersBucket: "noipm-referral-letters-test",
    complainantLettersBucket: "noipm-complainant-letters-test",
    authentication: {
      engine: "auth0",
      clientID: "iT3f0mGqJGDZu8UzQaOHeNGT7O0x43ZB",
      domain: "noipm-ci.auth0.com",
      audience: "test audience",
      issuer: "test issuer",
      algorithm: "RS256",
      scope: "openid profile",
      nicknameKey: "https://noipm-ci.herokuapp.com/nickname",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://noipm-ci.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        `wss://localhost:${LOCAL_DEV_PORT}`,
      ],
    },
    corsOrigin: "*",
    winston: {
      logLevel: "error",
      json: true,
    },
    queue: {
      host: "redis",
      port: 6379,
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  playground: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "noipm-playground",
    officerBucket: "nopd-officers-playground",
    exportsBucket: "noipm-exports-playground",
    referralLettersBucket: "noipm-referral-letters-playground",
    complainantLettersBucket: "noipm-complainant-letters-playground",
    authentication: {
      engine: "auth0",
      clientID: "iT3f0mGqJGDZu8UzQaOHeNGT7O0x43ZB",
      domain: "noipm-ci.auth0.com",
      publicKeyURL: "https://noipm-ci.auth0.com/.well-known/jwks.json",
      audience: "https://noipm-ci.herokuapp.com/",
      issuer: "https://noipm-ci.auth0.com/",
      algorithm: "RS256",
      nicknameKey: "https://noipm-ci.herokuapp.com/nickname",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://noipm-ci.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        "https://axi8fqxdfk.execute-api.us-east-1.amazonaws.com",
      ],
    },
    corsOrigin: "https://noipm-playground.herokuapp.com",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  ci: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "noipm-ci",
    officerBucket: "nopd-officers-ci",
    exportsBucket: "noipm-exports-ci",
    referralLettersBucket: "noipm-referral-letters-ci",
    complainantLettersBucket: "noipm-complainant-letters-ci",
    elasticIndexId:
      "noipm-deployment:dXMtZWFzdC0xLmF3cy5mb3VuZC5pbzo0NDMkYTM5MTE0NGNkNWEyNGY5N2I4Y2IwNjYzNGFjZTMzZDMk",
    authentication: {
      engine: "auth0",
      clientID: "iT3f0mGqJGDZu8UzQaOHeNGT7O0x43ZB",
      domain: "noipm-ci.auth0.com",
      publicKeyURL: "https://noipm-ci.auth0.com/.well-known/jwks.json",
      audience: "https://noipm-ci.herokuapp.com/",
      issuer: "https://noipm-ci.auth0.com/",
      algorithm: "RS256",
      nicknameKey: "https://noipm-ci.herokuapp.com/nickname",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://noipm-ci.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        "https://hggkf95dtf.execute-api.us-east-1.amazonaws.com",
      ],
    },
    corsOrigin: "https://noipm-ci.herokuapp.com",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  staging: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "noipm-staging",
    officerBucket: "nopd-officers-staging",
    exportsBucket: "noipm-exports-staging",
    referralLettersBucket: "noipm-referral-letters-staging",
    complainantLettersBucket: "noipm-complainant-letters-staging",
    elasticIndexId:
      "noipm-deployment:dXMtZWFzdC0xLmF3cy5mb3VuZC5pbzo0NDMkYTM5MTE0NGNkNWEyNGY5N2I4Y2IwNjYzNGFjZTMzZDMk",
    authentication: {
      engine: "auth0",
      clientID: "ALqHj5NyIIfLzHJks7LI4LqarbDN8jhe",
      domain: "noipm-staging.auth0.com",
      publicKeyURL: "https://noipm-staging.auth0.com/.well-known/jwks.json",
      audience: "https://noipm-staging.herokuapp.com/",
      issuer: "https://noipm-staging.auth0.com/",
      algorithm: "RS256",
      nicknameKey: "https://noipm-staging.herokuapp.com/nickname",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://noipm-staging.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        "https://49o089njl2.execute-api.us-east-1.amazonaws.com/",
      ],
    },
    corsOrigin: "https://noipm-staging.herokuapp.com",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  production: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "oipm-production",
    officerBucket: "pd-officers-production",
    exportsBucket: "oipm-exports-production",
    referralLettersBucket: "oipm-referral-letters-production",
    complainantLettersBucket: "oipm-complainant-letters-production",
    elasticIndexId:
      "oipm-deployment:dXMtZWFzdC0xLmF3cy5mb3VuZC5pbyRjMTIyMzIzYzc2ZDY0MDcxODQ5Y2ZiYWRjMmExZDU3MCQ1ZDJhMjllYzQwNjg0NTIxODk0Y2Y0MDZiOTFjZWQ4MA==",
    authentication: {
      engine: "auth0",
      clientID: "HdZblLPOunmyoPgF7X6GOs0ayTkssSbz",
      domain: "noipm-production.auth0.com",
      publicKeyURL: "https://noipm-production.auth0.com/.well-known/jwks.json",
      audience: "https://noipm-production.herokuapp.com/",
      issuer: "https://noipm-production.auth0.com/",
      algorithm: "RS256",
      nicknameKey: "https://noipm-production.herokuapp.com/nickname",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://noipm-production.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://maps.googleapis.com",
        "https://www.googletagmanager.com",
        "https://api.github.com",
        "https://roce011cj0.execute-api.us-east-1.amazonaws.com",
      ],
    },
    corsOrigin: "https://noipm-production.herokuapp.com",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
};
