import React from "react";
import { Divider, Typography } from "@material-ui/core";
import styles from "../../../../common/globalStyling/styles";
import LinkButton from "../../../shared/components/LinkButton";
import inBrowserDownload from "../../thunks/inBrowserDownload";
import { connect } from "react-redux";
import {
  COMPLAINANT_LETTER,
  REFERRAL_LETTER,
  USER_PERMISSIONS
} from "../../../../../sharedUtilities/constants";

const AttachmentsRow = ({
  attachment,
  onRemoveAttachment,
  dispatch,
  isArchived,
  permissions
}) => {
  const onDownloadClick = () =>
    dispatch(
      inBrowserDownload(
        `/api/cases/${attachment.caseId}/attachmentUrls?fileName=${attachment.fileName}`,
        `attachment-${attachment.id}-DownloadLink`
      )
    );
  const isComplainantLetter = attachment.description === COMPLAINANT_LETTER;
  const isReferralLetter = attachment.description === REFERRAL_LETTER;

  const checkPermissions = () => {
    const autogenerated = (
      <LinkButton
        data-testid={"autogeneratedButton"}
        disabled
        style={{ float: "right", fontSize: "12px" }}
      >
        Autogenerated
      </LinkButton>
    );
    const remove = (
      <LinkButton
        data-testid={"removeAttachmentButton"}
        style={{ float: "right" }}
        onClick={() => {
          onRemoveAttachment(attachment.id, attachment.fileName);
        }}
      >
        Remove
      </LinkButton>
    );

    if (permissions?.includes(USER_PERMISSIONS.EDIT_CASE)) {
      if (isComplainantLetter || isReferralLetter) {
        return autogenerated;
      } else if (!isArchived) {
        return remove;
      } else {
        return "";
      }
    } else {
      if (isComplainantLetter || isReferralLetter) {
        return autogenerated;
      } else if (isArchived) {
        return "";
      }
    }
  };

  return (
    <div>
      <div
        key={attachment.id}
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "8px",
          wordBreak: "break-word"
        }}
        data-testid="attachmentRow"
      >
        <div style={{ flex: 5, textAlign: "left" }}>
          <a
            href="#dynamicLink"
            id={`attachment-${attachment.id}-DownloadLink`}
          >
            {" "}
          </a>
          <Typography
            data-testid="attachmentName"
            style={{
              ...styles.link,
              cursor: "pointer"
            }}
            onClick={onDownloadClick}
          >
            {attachment.fileName}
          </Typography>
        </div>
        <div style={{ flex: 2, textAlign: "left" }}>
          <Typography variant="body2" data-testid="attachmentDescription">
            {attachment.description}
          </Typography>
        </div>
        <div style={{ flex: 2 }}>{checkPermissions()}</div>
      </div>
      <Divider style={{ marginBottom: "8px" }} />
    </div>
  );
};

const mapStateToProps = state => ({
  isArchived: state.currentCase.details.isArchived,
  permissions: state?.users?.current?.userInfo?.permissions
});

export default connect(mapStateToProps)(AttachmentsRow);
